import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { firebaseStorage } from "./firebase";
import resizeImage from "resize-image";

function randomString() {
  return Math.random().toString(16).substr(2, 5);
}

function removeFileExtension(file) {
  return file.name.substring(0, file.name.lastIndexOf("."));
}

export async function uploadFile(file) {
  const fileBlob = await resizeImageFile(file);
  const storageRef = ref(firebaseStorage, fileBlob.name);
  return uploadBytes(storageRef, fileBlob).then((snapshot) =>
    getDownloadURL(snapshot.ref)
  );
}

function imageSize(img) {
  const data = (width, height) =>
    resizeImage.resize(img, width, height, resizeImage.PNG);

  if (img.width > 600 && img.width >= img.height) {
    return data(600, (600 * img.height) / img.width);
  } else if (img.height > 400 && img.height >= img.width) {
    return data((400 * img.width) / img.height, 400);
  } else {
    return data(img.width, img.height);
  }
}

export function resizeImageFile(file) {
  const randomStr = randomString();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = async function () {
      const fileName = randomStr + removeFileExtension(file) + ".png";
      const data = imageSize(img);
      const resizedBlob = await fetch(data).then((response) => response.blob());
      resolve(new File([resizedBlob], fileName));
    };
    img.src = URL.createObjectURL(file);
  });
}

import React, { useState } from "react";
import { Formik } from "formik";

import { EmailSignatureConstructor } from "./templates";
import { SignatureForm, SignaturePreview } from "./organisms";
import { InstallationInstructions } from "./molecules";

export default function SignatureGeneratorPage() {
  const [signatureDetails, setSignatureDetails] = useState({});

  return (
    <div>
      <EmailSignatureConstructor
        signatureForm={
          <Formik
            initialValues={{
              photo: "",
              name: "",
              position: "",
              phone: "",
              office: "",
            }}
            onSubmit={(values) => {
              setSignatureDetails(values);
            }}
            onChange={(details) => {
              setSignatureDetails(details);
            }}
          >
            {({ ...props }) => (
              <SignatureForm
                {...props}
                changeSignatureDetails={(details) => {
                  setSignatureDetails(details);
                }}
              />
            )}
          </Formik>
        }
        signaturePreview={
          <SignaturePreview signatureDetails={signatureDetails} />
        }
        instructions={
          <InstallationInstructions signatureDetails={signatureDetails} />
        }
      />
    </div>
  );
}

import * as React from "react";
import { uploadFile } from "../../../lib/uploadTask";
import styles from "./style.module.css";

export class SignatureForm extends React.Component {
  render() {
    const {
      values,
      // errors,
      // touched,
      handleChange,
      handleBlur,
      setFieldValue,
      handleSubmit,
      changeSignatureDetails,
    } = this.props;

    const customHandleChange = (v) => {
      handleChange(v);
      changeSignatureDetails({ ...values, [v.target.name]: v.target.value });
    };

    return (
      <form onSubmit={handleSubmit}>
        <div>
          <legend className={styles.legendStyle}>Choose a photo</legend>
          <input
            id="photo"
            name="photo"
            type="file"
            onChange={(event) => {
              uploadFile(event.currentTarget.files[0]).then(function (
                downloadURL
              ) {
                setFieldValue("photo", downloadURL);
                changeSignatureDetails({
                  ...values,
                  photo: downloadURL,
                });
              });
            }}
          />
          <br />
          <legend className={styles.legendStyle}>Full name</legend>
          <input
            className={styles.inputStyle}
            name="name"
            onChange={customHandleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          <br />
          <legend className={styles.legendStyle}>Position</legend>
          <input
            className={styles.inputStyle}
            name="position"
            onChange={customHandleChange}
            onBlur={handleBlur}
            value={values.position}
          />
          <br />
          <legend className={styles.legendStyle}>Phone</legend>
          <input
            className={styles.inputStyle}
            name="phone"
            onChange={customHandleChange}
            onBlur={handleBlur}
            value={values.phone}
          />
          <br />
          <legend className={styles.legendStyle}>
            Office Phone (Optional)
          </legend>
          <input
            className={styles.inputStyle}
            name="office"
            onChange={customHandleChange}
            onBlur={handleBlur}
            value={values.office}
          />
        </div>
      </form>
    );
  }
}

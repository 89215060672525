import * as React from "react";
import styles from "./style.module.css";

export const EmailSignatureConstructor = ({
  instructions,
  signatureForm,
  signaturePreview,
}) => (
  <div className={styles.row}>
    <div className={styles.col}>
      <h1 className={styles.heading}>Email Signature Generator</h1>
      <p>
        Fill out the fields below, and read the instructions to install the
        signature.
      </p>
      {signatureForm}
      {instructions}
    </div>

    <div className={styles.colRight}>
      <div className={styles.isVisible}>{signaturePreview}</div>
    </div>
  </div>
);

import * as React from "react";
import styles from "./App.module.css";
import { SignIn } from "./modules/email-signature/login/SignIn";
import SignatureGeneratorPage from "./modules/email-signature/SignatureGeneratorPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState } from "react";

export default function App() {
  return (
    <div className={styles.rootTemplateContainer}>
      <GoogleOAuthProvider
        clientId={
          "909133020380-2d865mlhjpp99adms5rkdkflesjpl3tr.apps.googleusercontent.com"
        }
      >
        <Pages />
      </GoogleOAuthProvider>
    </div>
  );
}

function Pages() {
  const [authState, setAuthState] = useState("unauthorized");

  if (authState === "unknown") {
    return <span className={styles.layout}>Loading...</span>;
  }

  if (authState === "authorized") {
    return <SignatureGeneratorPage />;
  }

  return <SignIn onAuthStateChanged={setAuthState} />;
}

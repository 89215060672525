import * as React from "react";
import ClipboardJS from "clipboard";
import styles from "./style.module.css";

new ClipboardJS(".copy-to-clipboard");

export class InstallationInstructions extends React.Component {
  constructor() {
    super();
    this.state = {
      exchangeServerHtml: "",
    };
  }
  componentWillReceiveProps(newProps) {
    if (newProps.selectedPlatform !== this.props.selectedPlatform) {
      this.setState({
        exchangeServerHtml: "",
      });
    }
  }
  render() {
    return (
      <div className={styles.installationInstructions}>
        <h4>Instructions</h4>
        <ol>
          <li>
            <button
              className="copy-to-clipboard"
              data-clipboard-target="#signaturePreview"
            >
              Copy to clipboard
            </button>
          </li>
          <li>
            Open{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://mail.google.com/mail/#settings/general"
            >
              Gmail settings
            </a>
            .
          </li>
          <li>
            Scroll down to the
            <strong> Signature</strong> section. Edit existing or create a new
            signature.
          </li>
          <li>
            <strong>Paste</strong> the copied signature.
          </li>
          <li>Save changes.</li>
        </ol>
        Refer to the official{" "}
        <a href="https://support.google.com/mail/answer/8395?hl=en#null">
          documentation
        </a>{" "}
        if you have any issues.
      </div>
    );
  }
}

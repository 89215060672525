import * as firebase from "firebase/app";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyB2kR8vZc7BGGXAxF4tXsYvMNrUiuEvwhU",
  authDomain: "thematic-flash-786.firebaseapp.com",
  databaseURL: "https://thematic-flash-786.firebaseio.com",
  projectId: "thematic-flash-786",
  storageBucket: "superdispatch-email-signatures",
  messagingSenderId: "909133020380",
  appId: "1:909133020380:web:57eacdb747c6225b058819",
  measurementId: "G-JL42NT2VVB",
};

const app = firebase.initializeApp(firebaseConfig);
export const firebaseStorage = getStorage(app);

import * as React from "react";
import styles from "./style.module.css";
import { useGoogleLogin } from "@react-oauth/google";

export function SignIn({ onAuthStateChanged }) {
  const login = useGoogleLogin({
    hosted_domain: "superdispatch.com",
    onSuccess: (tokenResponse) => {
      if (tokenResponse.hd === "superdispatch.com") {
        onAuthStateChanged("authorized");
      } else {
        alert("Sign in with superdispatch.com!");
      }
    },
    onError: (errorResponse) => {
      alert("Login failed" + errorResponse.error);
    },
  });
  return (
    <div className={styles.layout}>
      <h2>Login with your corporate credentials</h2>
      <button onClick={() => login()} className={styles.login}>
        LOG IN AT SUPERDISPATCH.COM
      </button>
    </div>
  );
}

import * as React from "react";
import styles from "./style.module.css";

export class SignaturePreview extends React.Component {
  render() {
    const {
      signatureDetails: { photo, name, position, phone, office },
    } = this.props;
    return (
      <div>
        <h3>Preview</h3>
        <div>
          <p>
            This is Ground Control to Major Tom <br />
            You've really made the grade <br />
            And the papers want to know whose shirts you wear <br />
            Now it's time to leave the capsule if you dare <br />
            This is Major Tom to Ground Control <br />
            I'm stepping through the door <br />
            And I'm floating in a most peculiar way <br />
            And the stars look very different today
          </p>
          <p>
            For here am I sitting in my tin can <br />
            Far above the world <br />
            Planet Earth is blue <br />
            And there's nothing I can do
          </p>
          <p>--</p>
        </div>
        <div className={styles.signatureWrapper} id="signaturePreview">
          <table className={styles.tableStyle} cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                {!!photo && (
                  <td className={styles.previewImgCover} valign="middle">
                    <img
                      border="0"
                      alt="Logo"
                      width="88"
                      height="88"
                      className={styles.previewImg}
                      src={photo}
                    />
                  </td>
                )}
                <td className={styles.innerWrap} valign="middle">
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    className={styles.innerTableStyle}
                  >
                    <tbody>
                      {name && (
                        <tr>
                          <td className={styles.previewNameCover}>
                            <strong className={styles.previewName}>
                              {name}
                            </strong>
                          </td>
                        </tr>
                      )}
                      {!!position && (
                        <tr>
                          <td className={styles.previewPositionCover}>
                            {position},{" "}
                            <a href="https://www.superdispatch.com/">
                              Super&nbsp;Dispatch
                            </a>
                            {/* <span className={styles.previewPosition}></span> */}
                          </td>
                        </tr>
                      )}
                      {!!phone && (
                        <tr>
                          <td className={styles.previewPhoneCover}>
                            <span className={styles.previewPhoneText}>
                              <div>Phone: {phone}</div>
                            </span>
                          </td>
                        </tr>
                      )}
                      {!!office && (
                        <tr>
                          <td className={styles.previewOfficeCover}>
                            <span className={styles.previewPhoneText}>
                              <div>Office: {office}</div>
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
